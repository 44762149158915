<div class="nested-rule-row ml-x1 mt-x1">
  <div class="clr-col-sm">
    @defer (on timer(1ms)) {
      <dpa-custom-attribute-identifier-selector
        [selectedIdentifier]="filterRule.customAttributeIdentifier"
        [parentAttributeName]="parentFilterRule.attribute"
        (identifierChange)="onIdentifierChange($event)"
      ></dpa-custom-attribute-identifier-selector>
    }
  </div>

  <div class="clr-col-sm">
    @defer (on timer(1ms)) {
      @if (identifierAttributes?.length > 0) {
        <dpa-type-ahead
          [selectedItem]="getSelectedIdentifierAttribute()"
          [searchableItems]="identifierAttributes"
          [keyBy]="getColumnKey"
          [labelFormatter]="columnFormatter"
          [customFormatter]="columnFormatter"
          [placeholderText]="'COMMON_ACTIONS.SELECT_ATTRIBUTE' | translate"
          (selectedItemChange)="onIdentifierAttributeChange($event)"
        ></dpa-type-ahead>
      }
    }
  </div>

  <div class="clr-col-sm">
    @defer (on timer(1ms)) {
      @if (getSelectedIdentifierAttributeOperators(filterRule)?.length > 0) {
        <dpa-type-ahead
          [selectedItem]="getSelectedIdentifierAttributeOperator(filterRule)"
          [searchableItems]="getSelectedIdentifierAttributeOperators(filterRule)"
          [keyBy]="getOperatorKey"
          [labelFormatter]="columnFormatter"
          [customFormatter]="columnFormatter"
          [placeholderText]="'COMMON_ACTIONS.SELECT_OPERATOR' | translate"
          (selectedItemChange)="onIdentifierAttributeOperatorChange($event)"
        ></dpa-type-ahead>
      }
    }
  </div>

  <div class="clr-col-sm">
    @defer (on timer(1ms)) {
      @if (filterRule?.condition && filterRule?.valueRequired) {
        <dpa-filter-value
          [disabled]="!getSelectedIdentifierAttribute()"
          [filterRule]="filterRule"
          [column]="getSelectedIdentifierAttribute()"
          [placeholderText]="'COMMON_ACTIONS.ENTER_VALUE' | translate"
          [selectedValue]="filterRule.data"
          [suggestionFilterBys]="suggestionFilterBys"
          [suggestionCategory]="suggestionCategory"
          [selectedCondition]="getSelectedIdentifierAttributeOperator(filterRule)"
          [dropdownAlignRight]="true"
          [delimiterSupported]="delimiterSupported"
          (selectedValueChange)="onFilterRuleDataChange($event)"
        ></dpa-filter-value>
      }
    }
  </div>
</div>
